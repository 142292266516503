import Axios from 'axios';
import store from '../store'


const RESOURCE_SECURITY = 'https://f65p9by2x0.execute-api.us-west-2.amazonaws.com/api';
const RESOURCE_IMG = 'https://wddjvyb1v4.execute-api.us-west-2.amazonaws.com/api';
const RESOURCE_DB = 'https://qm5il8xpt8.execute-api.us-west-2.amazonaws.com/api';

Axios.interceptors.request.use(function(config) {
    const auth_token = store.getters.token;
    if(auth_token!='') {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

export default {

  login(datos)
  {
    return Axios.post(`${RESOURCE_SECURITY}/login/`,datos)
  },
  SaveFilter(datos)
  {
    return Axios.post( `${RESOURCE_DB}/agregar_filtro/`,datos)
    
  },
  SavePolitic(datos)
  {
    return Axios.post( `${RESOURCE_DB}/agregar_politica_grupo/`,datos)
    
  },
  DeleteFilter(datos)
  {
    return Axios.post( `${RESOURCE_DB}/eliminar_filtro/`,datos)
    
  },
  DeletePolitic(datos)
  {
    return Axios.post( `${RESOURCE_DB}/eliminar_politica/`,datos)
    
  },
  DeleteQuestion(datos)
  {
    return Axios.post( `${RESOURCE_DB}/eliminar_pregunta/`,datos)
    
  },
  SavePlace(datos)
  {
    return Axios.post( `${RESOURCE_DB}/guardar_pais/`,datos)
    
  },
  GetPolitics()
  {
    return Axios.post( `${RESOURCE_DB}/buscar_politicas_eliminar/`)
  },
  GetFilters()
  {
    return Axios.post( `${RESOURCE_DB}/buscar_filtros/`)
  },
  GetQuestions()
  {
    return Axios.post( `${RESOURCE_DB}/buscar_preguntas/`)
  },
  SaveQuestion(data)
  {
    return Axios.post( `${RESOURCE_DB}/agregar_pregunta/`,data)
  },
  savePoliticPDF(pdf,id)
  {
    let formData = new FormData();
  
    
    formData.append('pdf',pdf);
    formData.append('id',id);
    
    return Axios.post( `${RESOURCE_IMG}/guardar_pdf`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  saveImage(img, nombre,liga)
  {
    let formData = new FormData();
  
    
    formData.append('img',img);
    formData.append('nombre',nombre);
    formData.append('liga',liga);
    
    return Axios.post( `${RESOURCE_IMG}/guardar_imagen`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  saveCover(img, titulo,texto, texto_completo)
  {
    let formData = new FormData();
  
    
    formData.append('img',img);
    formData.append('titulo',titulo);
    formData.append('texto',texto);
    formData.append('texto_completo',texto_completo);
    
    return Axios.post( `${RESOURCE_IMG}/guardar_portada`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  savePaper(file)
  {
    let formData = new FormData();
  
    
    formData.append('file',file);
    
    return Axios.post( `${RESOURCE_IMG}/guardar_revista`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  saveCollaboration(img, titulo, texto_completo,id)
  {
    let formData = new FormData();
  
    formData.append('id',id);
    formData.append('img',img);
    formData.append('titulo',titulo);
    formData.append('texto_completo',texto_completo);
    
    return Axios.post( `${RESOURCE_IMG}/guardar_colaboracion`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  }
  
  
};