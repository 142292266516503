import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'',
    tokenPayload:'',
    system_id:'df64298e-5138-11ec-ad72-027986cdc406',
    
  },
  getters:
  {
   system_id: state=>{
     return state.system_id;  
   },
   token: state=>{
     return state.token;
   },
   tokenPayload: state=>{
     return state.tokenPayload
   },
   
  },
  mutations: {
    new_token:(state,new_token)=>{
      state.token=new_token;
       var base64Url = new_token.split('.')[1];
       var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
   state.tokenPayload=JSON.parse(base64);
    
    },
    logout:(state)=>
    {
       state.token="";
       state.tokenPayload="";
        
    },
    
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
