<template>
  <v-container>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Políticas Públicas
        </h1>
 <v-text-field label="Usuario" v-model="username"></v-text-field>
 <v-text-field  type="password" label="Clave" v-model="password"></v-text-field>
 <v-btn @click="Login('Login!', $event)"
  elevation="15"
>Entrar</v-btn>
        
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import aws_service from '@/services/aws';
import {mapState, mapMutations} from 'vuex';
  export default {
    name: 'HelloWorld',
computed:{
      ...mapState([
    'token','tokenPayload','clients','system_id'
    ])},
     data: function(){
      return {
        username: '',
        password:'',
        name:'',
        mensajeModal:"",
        clientes:[],
        show:false
        
      }
    },
    
     methods: {
    ...mapMutations(['new_token','logout','set_clients']),
    Login: function(msg,e) {
        e.preventDefault();
        //admin  P4484
        var data={"system_id":this.system_id,"username":this.username,"password":this.password}
        console.log(data);
        
        aws_service.login(data).then((response) => {
          
          this.new_token(response.data.token);
          this.name=this.tokenPayload.data.name;
          console.log(this.token);
          this.$router.push('carga')
          
            
        }).catch((error) => {
          console.log(error.response.data);
           alert("Error al iniciar, favor de validar tus credenciales");
           modal('#modal-center').hide();
          console.log(this.token);
          
        });
    },
     
    Logout:function (msg,e) {
      e.preventDefault();
      this.password="";
      this.logout();
      
    }
    
  }
    
  }
</script>
