<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h2>Políticas Públicas</h2>

        
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://politicaspublicas.org"
        target="_blank"
        text
      >
        <span class="mr-2">Ir a página</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
