<template>
  <v-container>
       
     <v-row class="text-center">
       <v-col class="mb-4">
      <h2 class="display-2 font-weight-bold mb-3">
          Preguntas
        </h2>
         </v-col>
     </v-row>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Crear
        </h2>
          
 <v-text-field label="Título" v-model="titulo"></v-text-field>
 <v-textarea v-model="pregunta"
          name="input-7-1"
          label="Pregunta"
          
        ></v-textarea>
 <v-btn  @click="GuardarPregunta"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

<v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Eliminar
        </h2>
          <v-select
              v-model="pregunta_eliminar"
          
          :items="preguntas"
          item-text="pregunta"
          item-value="valor"
          label="Anuncio"
          persistent-hint
          return-object
          single-line
        ></v-select>
 <v-btn  @click="EliminarPregunta"
  elevation="15"
>Eliminar</v-btn>
        
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import aws_service from '@/services/aws';
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

  export default {
    
    name: 'HelloWorld',

    data: () => ({
       
        preguntas: [
          { pregunta: 'Seleciona una pregunta', valor: '0' },
          
        ],
      titulo:"",
      pregunta:""
        

    }),
     mounted() {
            this.ObtenerPreguntas();
            
        },
    methods: {
    
      ObtenerPreguntas()
      {
        aws_service.GetQuestions().then((response) => {
            console.log(response.data);
             response.data.forEach(this.AddQuestions)
              
        }).catch((error) => {
          console.log(error);
          alert(error);
        });
      },
      AddQuestions(pregunta, index) {
                this.preguntas.push(
                    {pregunta:pregunta.titulo+" - "+ pregunta.pregunta,valor:pregunta.id}
                );
      },
      

     GuardarPregunta(){
       var data={ titulo:this.titulo , pregunta:this.pregunta}
       console.log(data)
        aws_service.SaveQuestion(data).then((response) => {
            console.log(response);
            alert("Pregunta guardada");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      EliminarPregunta(){
       var data={ pregunta:this.pregunta_eliminar.valor.toString()}
       console.log(data)
        aws_service.DeleteQuestion(data).then((response) => {
            console.log(response);
            alert("Catálogo eliminado");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },

    }
  }
</script>
