<template>
  <v-container>
    <v-row class="text-center">
       <v-col class="mb-4">
      <h2 class="display-2 font-weight-bold mb-3">
          Política
        </h2>
         </v-col>
     </v-row>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Crear
        </h2>
          <v-select
              v-model="palabras"
          
          :items="filtros.filter(x=>x.tipo==1)"
          item-text="nombre"
          item-value="valor"
          label="Palabras clave"
          hint="Palabras clave"
          multiple
          persistent-hint
          return-object
          single-line
        ></v-select>
        
        <v-select
              v-model="lugares"
          :items="filtros.filter(x=>x.tipo==2)"
          item-text="nombre"
          item-value="valor"
          label="Lugar"
          hint="Lugar"
          multiple
          persistent-hint
          return-object
          single-line
        ></v-select>
        
        <v-select
              v-model="temas"
          :items="filtros.filter(x=>x.tipo==3)"
          item-text="nombre"
          item-value="valor"
          label="Tema"
          hint="Tema"
          multiple
          persistent-hint
          return-object
          single-line
        ></v-select>
        
     <!--   <v-select
              v-model="paises"
          :items="filtros.filter(x=>x.tipo==4)"
          item-text="nombre"
          item-value="valor"
          label="País"
          hint="País"
          multiple
          persistent-hint
          return-object
          single-line
        ></v-select>
        -->
        
        
        
 <v-file-input truncate-length="15" label="Archivo" id="file" ref="file" accept="application/pdf" v-model="file"></v-file-input>

 <v-text-field label="Nombre" v-model="nombre"></v-text-field>
 <v-select
              v-model="grupo"
          
          :items="grupos"
          item-text="grupo"
          item-value="valor"
          label="Grupo"
          persistent-hint
          return-object
          single-line
        ></v-select>
 <v-btn   @click="GuardarPolitica"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

<v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Eliminar
        </h2>
          <v-select
              v-model="politica_borrar"
          
          :items="politicas"
          item-text="politica"
          item-value="valor"
          label="Anuncio"
          persistent-hint
          return-object
          single-line
        ></v-select>
 
 <v-btn @click="EliminarPolitica"
  elevation="15"
>Eliminar</v-btn>
        
      </v-col>

    </v-row>
    
    
    <v-row class="text-center">
       <v-col class="mb-4">
      <h2 class="display-2 font-weight-bold mb-3">
          Catálogos
        </h2>
         </v-col>
     </v-row>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Crear
        </h2>
          <v-select
              v-model="tipo_filtro"
          
          :items="tipos"
          item-text="tipo"
          item-value="valor"
          label="Tipo"
          persistent-hint
          return-object
          single-line
        ></v-select>
 
 <v-text-field label="Nombre" v-model="nombre_filtro"></v-text-field>
 
 <v-btn  @click="GuardarFiltro"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

<v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Eliminar
        </h2>
          <v-select
              v-model="filtro_eliminar"
          
          :items="filtros"
          item-text="filtro"
          item-value="valor"
          label="Catálogo"
          persistent-hint
          return-object
          single-line
        ></v-select>
 <v-btn  @click="EliminarFiltro"
  elevation="15"
>Eliminar</v-btn>
        
      </v-col>

    </v-row>
    
    
     <v-row class="text-center">
       <v-col class="mb-4">
      <h2 class="display-2 font-weight-bold mb-3">
          Lugares
        </h2>
         </v-col>
     </v-row>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          
        </h2>
          <v-select
              v-model="lugar"
          
          :items="filtros.filter(x=>x.tipo==2) "
          item-text="filtro"
          item-value="valor"
          label="Lugares"
          persistent-hint
          return-object
          single-line
        ></v-select>
        
        <v-select
              v-model="pais"
          
          :items="filtros.filter(x=>x.tipo==4) "
          item-text="filtro"
          item-value="valor"
          label="Paises"
          persistent-hint
          return-object
          single-line
        ></v-select>

 <v-btn  @click="GuardarLugar"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import aws_service from '@/services/aws';
  export default {
    
    name: 'HelloWorld',

    data: () => ({
       anuncio: { anuncio: 'Selecciona anuncio', valor: '0' },
        items: [
          { anuncio: 'Seleciona anuncio', valor: '0' },
          { anuncio: 'Inicial horizontal 1', valor: '1-h-1' },
          { anuncio: 'Inicial horizontal 2', valor: '1-h-2' },
          { anuncio: 'Inicial horizontal 3', valor: '1-h-3' },
          { anuncio: 'Inicial vertical 1', valor: '1-v-1' },
          { anuncio: 'Inicial vertical 2', valor: '1-v-2' },
          { anuncio: 'Artículo inicial horizontal 1', valor: '2-h-1' },
          { anuncio: 'Artículo inicial horizontal 2', valor: '2-h-2' },
          { anuncio: 'Artículo inicial horizontal 3', valor: '2-h-3' },
          { anuncio: 'Artículo inicial vertical 1', valor: '2-v-1' },
          { anuncio: 'Colaboraciones horizontal 1', valor: '3-h-1' },
          { anuncio: 'Colaboraciones horizontal 2', valor: '3-h-2' },
          { anuncio: 'Colaboraciones horizontal 3', valor: '3-h-3' },
          { anuncio: 'Colaboraciones vertical 1', valor: '3-v-1' },
          { anuncio: 'Artículo colaboraciones horizontal 1', valor: '4-h-1' },
          { anuncio: 'Artículo colaboraciones horizontal 2', valor: '4-h-2' },
          { anuncio: 'Artículo colaboraciones horizontal 3', valor: '4-h-3' },
          { anuncio: 'Artículo colaboraciones vertical 1', valor: '4-v-1' },
          { anuncio: 'Artículo colaboraciones vertical 2', valor: '4-v-2' },
          { anuncio: 'Colaboradores horizontal 1', valor: '5-h-1' },
          { anuncio: 'Colaboradores horizontal 2', valor: '5-h-2' },
          { anuncio: 'Colaboradores horizontal 3', valor: '5-h-3' },
          { anuncio: 'Colaboradores vertical 1', valor: '5-v-1' },
          { anuncio: 'Colaboradores vertical 2', valor: '5-v-2' },
          { anuncio: 'Consultores horizontal 1', valor: '6-h-1' },
          { anuncio: 'Consultores horizontal 2', valor: '6-h-2' },
          { anuncio: 'Consultores horizontal 3', valor: '6-h-3' },
          { anuncio: 'Consultores vertical 1', valor: '6-v-1' },
          { anuncio: 'Detalle consultor horizontal 1', valor: '7-h-1' },
          { anuncio: 'Detalle consultor horizontal 2', valor: '7-h-2' },
          { anuncio: 'Detalle consultor horizontal 3', valor: '7-h-3' },
          { anuncio: 'Detalle consultor vertical 1', valor: '7-v-1' },
          { anuncio: 'Buscador horizontal 1', valor: '8-h-1' },
          { anuncio: 'Buscador horizontal 2', valor: '8-h-2' },
          { anuncio: 'Buscador horizontal 3', valor: '8-h-3' },
          { anuncio: 'Buscador vertical 1', valor: '8-v-1' },
          { anuncio: 'Foro vertical 1', valor: '9-v-1' },
          { anuncio: 'Foro vertical 2', valor: '9-v-2' },
          { anuncio: 'Contacto vertical 2', valor: '10-v-1' },
        ],
         tipos: [
          { tipo: 'Seleciona tipo', valor: '0' },
          { tipo: 'Palabra clave', valor: '1' },
          { tipo: 'Lugar', valor: '2' },
          { tipo: 'Tema', valor: '3' },
           { tipo: 'Pais', valor: '4' },
          
        ],
        grupos: [
          
          { grupo: 'Fuentes originales', valor: '1' },
          { grupo: 'Cuadros comparativos', valor: '2' },
          { grupo: 'Análisis', valor: '3' },
          { grupo: 'Fichas técnicas', valor: '4' },
          
        ],
         filtros: [
          { filtro: 'Seleciona catálogo', valor: '0' },
          
        ],
        politicas: [
          { politica: 'Seleciona política', valor: '0' },
          
        ],
        tipo_anuncio:0,
        tipo_filtro:0,
        filtro_eliminar:0,
        politica_borrar:0,
        nombre_filtro:"",
        palabras:[],
        lugares :[],
        temas:[],
        paises:[],
        nombre:"",
        archivo:'',
        grupo:'0',
        lugar:0,
        pais:0,
        file:null,
        

    }),
     mounted() {
            this.ObtenerFiltros();
            this.ObtenerPoliticas();

        },
    methods: {
      EliminarPolitica()
      {
        console.log(this.politica_borrar)
        var data={ politica:this.politica_borrar.valor.toString()}
       console.log(data)
        aws_service.DeletePolitic(data).then((response) => {
            console.log(response);
            alert("Política eliminada");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
      },
      ObtenerPoliticas()
      {
        aws_service.GetPolitics().then((response) => {
            console.log(response.data);
             response.data.forEach(this.AddPolitic)
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
      },
      AddPolitic(politica, index) {
                this.politicas.push(
                    {politica:politica.nombre,valor:politica.id}
                );
      },
      ObtenerFiltros()
      {
        aws_service.GetFilters().then((response) => {
            console.log(response.data);
             response.data.forEach(this.AddFilter)
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
      },
      AddFilter(filter, index) {
        var tipo=""
        if(filter.tipo==1)
        {
          tipo= "Palabra"
        }
        if(filter.tipo==2)
        {
          tipo= "Lugar"
        }
        if(filter.tipo==3)
        {
          tipo= "Tema"
        }
        if(filter.tipo==4)
        {
          tipo= "País"
        }
        
                this.filtros.push(
                    {filtro:tipo+": "+filter.nombre,nombre:filter.nombre,valor:filter.id, tipo:filter.tipo}
                );
      },
      GuardarPortada(){
        aws_service.saveCover(this.file,this.titulo,this.texto, this.texto_completo).then((response) => {
            console.log(response);
            alert("Portada guardada");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      GuardarLugar()
      {
             var data= {lugar:this.lugar.valor, pais:this.pais.valor}
          aws_service.SavePlace(data).then((response) => {
            console.log(response);
            alert("Lugar asignado");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
          
      },
      AdministrarArchivo(){
        this.archivo = this.$refs.file.files[0];
        console.log(this.archivo);
        
    },
     GuardarFiltro(){
       var data={ tipo:this.tipo_filtro.valor , nombre:this.nombre_filtro}
       console.log(data)
        aws_service.SaveFilter(data).then((response) => {
            console.log(response);
            alert("Catálogo guardado");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      GuardarPolitica(){
      console.log()
        console.log(this.lugares)
        console.log(this.temas)
        var filtros=[]
         
         for(var i=0;i<this.palabras.length;i++)
         {
           filtros.push(this.palabras[i].valor)
         }
         for(var i=0;i<this.lugares.length;i++)
         {
           filtros.push(this.lugares[i].valor)
         }
         for(var i=0;i<this.temas.length;i++)
         {
           filtros.push(this.temas[i].valor)
         }
         for(var i=0;i<this.paises.length;i++)
         {
           filtros.push(this.paises[i].valor)
         }
         
         
        var data= {nombre:this.nombre, filtros:filtros, grupo:this.grupo.valor}
        console.log(data)
        aws_service.SavePolitic(data).then((response) => {
            console.log(response.data.id);
            
            
            aws_service.savePoliticPDF(this.file,response.data.id).then((response) => {
            console.log(response);
            alert("Política registrada");
              
            }).catch((error) => {
              console.log(error.response.data);
              alert(error.response.data);
            });
            
        
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
      },
      EliminarFiltro(){
       var data={ filtro:this.filtro_eliminar.valor.toString()}
       console.log(data)
        aws_service.DeleteFilter(data).then((response) => {
            console.log(response);
            alert("Catálogo eliminado");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
       AdministrarArchivo(){
        this.archivo = this.$refs.file.files[0];
        console.log(this.archivo);
        
    },
    }
  }
</script>
