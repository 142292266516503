<template>
  <v-container>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Anuncio
        </h2>
          <v-select
              v-model="anuncio"
          
          :items="items"
          item-text="anuncio"
          item-value="valor"
          label="Anuncio"
          persistent-hint
          return-object
          single-line
        ></v-select>
 <v-file-input truncate-length="15" label="Imagen Anuncio" id="file" ref="file" accept="image/.jpg"  v-model="file"></v-file-input>

 <v-text-field v-model="liga" label="liga"></v-text-field>
 <v-btn @click="GuardarAnuncio"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import aws_service from '@/services/aws';
  export default {
    name: 'HelloWorld',

    data: () => ({
       anuncio: { anuncio: 'Selecciona anuncio', valor: '0' },
        items: [
          { anuncio: 'Seleciona anuncio', valor: '0' },
          { anuncio: 'Inicial horizontal 1', valor: '1-h-1' },
          { anuncio: 'Inicial horizontal 2', valor: '1-h-2' },
          { anuncio: 'Inicial horizontal 3', valor: '1-h-3' },
          { anuncio: 'Inicial vertical 1', valor: '1-v-1' },
          { anuncio: 'Inicial vertical 2', valor: '1-v-2' },
          { anuncio: 'Artículo inicial horizontal 1', valor: '2-h-1' },
          { anuncio: 'Artículo inicial horizontal 2', valor: '2-h-2' },
          { anuncio: 'Artículo inicial horizontal 3', valor: '2-h-3' },
          { anuncio: 'Artículo inicial vertical 1', valor: '2-v-1' },
          { anuncio: 'Colaboraciones horizontal 1', valor: '3-h-1' },
          { anuncio: 'Colaboraciones horizontal 2', valor: '3-h-2' },
          { anuncio: 'Colaboraciones horizontal 3', valor: '3-h-3' },
          { anuncio: 'Colaboraciones vertical 1', valor: '3-v-1' },
          { anuncio: 'Artículo colaboraciones 1 horizontal 1', valor: '4-1-h-1' },
          { anuncio: 'Artículo colaboraciones 1 horizontal 2', valor: '4-1-h-2' },
          { anuncio: 'Artículo colaboraciones 1 horizontal 3', valor: '4-1-h-3' },
          { anuncio: 'Artículo colaboraciones 1 vertical 1', valor: '4-1-v-1' },
          { anuncio: 'Artículo colaboraciones 1 vertical 2', valor: '4-1-v-2' },
          { anuncio: 'Artículo colaboraciones 2 horizontal 1', valor: '4-2-h-1' },
          { anuncio: 'Artículo colaboraciones 2 horizontal 2', valor: '4-2-h-2' },
          { anuncio: 'Artículo colaboraciones 2 horizontal 3', valor: '4-2-h-3' },
          { anuncio: 'Artículo colaboraciones 2 vertical 1', valor: '4-2-v-1' },
          { anuncio: 'Artículo colaboraciones 2 vertical 2', valor: '4-2-v-2' },
          { anuncio: 'Artículo colaboraciones 3 horizontal 1', valor: '4-3-h-1' },
          { anuncio: 'Artículo colaboraciones 3 horizontal 2', valor: '4-3-h-2' },
          { anuncio: 'Artículo colaboraciones 3 horizontal 3', valor: '4-3-h-3' },
          { anuncio: 'Artículo colaboraciones 3 vertical 1', valor: '4-3-v-1' },
          { anuncio: 'Artículo colaboraciones 3 vertical 2', valor: '4-3-v-2' },
          { anuncio: 'Artículo colaboraciones 4 horizontal 1', valor: '4-4-h-1' },
          { anuncio: 'Artículo colaboraciones 4 horizontal 2', valor: '4-4-h-2' },
          { anuncio: 'Artículo colaboraciones 4 horizontal 3', valor: '4-4-h-3' },
          { anuncio: 'Artículo colaboraciones 4 vertical 1', valor: '4-4-v-1' },
          { anuncio: 'Artículo colaboraciones 4 vertical 2', valor: '4-4-v-2' },
          { anuncio: 'Colaboradores horizontal 1', valor: '5-h-1' },
          { anuncio: 'Colaboradores horizontal 2', valor: '5-h-2' },
          { anuncio: 'Colaboradores horizontal 3', valor: '5-h-3' },
          { anuncio: 'Colaboradores vertical 1', valor: '5-v-1' },
          { anuncio: 'Colaboradores vertical 2', valor: '5-v-2' },
          { anuncio: 'Consultores horizontal 1', valor: '6-h-1' },
          { anuncio: 'Consultores horizontal 2', valor: '6-h-2' },
          { anuncio: 'Consultores horizontal 3', valor: '6-h-3' },
          { anuncio: 'Consultores vertical 1', valor: '6-v-1' },
          { anuncio: 'Detalle consultor horizontal 1', valor: '7-h-1' },
          { anuncio: 'Detalle consultor horizontal 2', valor: '7-h-2' },
          { anuncio: 'Detalle consultor horizontal 3', valor: '7-h-3' },
          { anuncio: 'Detalle consultor vertical 1', valor: '7-v-1' },
          { anuncio: 'Buscador horizontal 1', valor: '8-h-1' },
          { anuncio: 'Buscador horizontal 2', valor: '8-h-2' },
          { anuncio: 'Buscador horizontal 3', valor: '8-h-3' },
          { anuncio: 'Buscador vertical 1', valor: '8-v-1' },
          { anuncio: 'Foro vertical 1', valor: '9-v-1' },
          { anuncio: 'Foro vertical 2', valor: '9-v-2' },
          { anuncio: 'Contacto vertical 2', valor: '10-v-1' },
          
          
        ],
        archivo:'',
        file:null,
        liga:"",
    }),
    methods: {
      GuardarAnuncio(){
        aws_service.saveImage(this.file,this.anuncio.valor,this.liga).then((response) => {
            console.log(response);
            alert("Anuncio guardado");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      AdministrarArchivo(){
        this.archivo = this.$refs.file.files[0];
        console.log(this.archivo);
        
    },
    }
  }
</script>
