<template>
  <v-container>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Portada
        </h2>
 
 <v-file-input truncate-length="15" label="Imagen" id="file" ref="file" accept="image/.jpg"  v-model="file"></v-file-input>
 <v-text-field v-model="titulo" label="Titulo"></v-text-field>
  <v-textarea v-model="texto"
          name="input-7-1"
          label="Texto corto"
          
        ></v-textarea>
        <v-textarea v-model="texto_completo"
          name="input-7-1"
          label="Texto completo"
          
        ></v-textarea>
  <!--<editor-content class="editor" v-model="texto" :editor="editor" />
  <editor-content class="editor" v-model="texto_completo" :editor="editorCompleto" />-->
 <v-btn @click="GuardarPortada"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import aws_service from '@/services/aws';
  export default {
    
    name: 'Portada',
     components: {
    EditorContent,
  },
  mounted() {
    /*this.editor = new Editor({
      content: '<p>Texto Portada</p>',
      extensions: [
        StarterKit,
      ],
    });
    this.editorCompleto = new Editor({
      content: '<p>Texto Completo</p>',
      extensions: [
        StarterKit,
      ],
    })*/
  },

  beforeDestroy() {
    /*this.editor.destroy()
    this.editorCompleto.destroy()*/
  },

    data: () => ({
       editor: null,
       editorCompleto: null,
       titulo:"",
       archivo:'',
      file:null,
      texto:"",
      texto_completo:""
      
    }),
     methods: {
      GuardarPortada(){
        aws_service.saveCover(this.file,this.titulo,this.texto, this.texto_completo).then((response) => {
            console.log(response);
            alert("Portada guardada");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      AdministrarArchivo(){
        this.archivo = this.$refs.file.files[0];
        console.log(this.archivo);
        
    },
    }
  }
</script>
<style>
.editor
{
  
  border:1px solid #000;
  
}
</style>
