<template>
  <v-container>
    <v-row class="text-center">
      
      <v-col class="mb-4">
        <h2 class="display-2 font-weight-bold mb-3">
          Revista
        </h2>
 
 <v-file-input truncate-length="15" label="Imagen" id="file" ref="file" accept="application/pdf"  v-model="file"></v-file-input>
 <v-btn @click="GuardarRevista"
  elevation="15"
>Guardar</v-btn>
        
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import aws_service from '@/services/aws';
  export default {
    
    name: 'Portada',
     components: {
    EditorContent,
  },
  mounted() {
    
  },

  beforeDestroy() {
    
  },

    data: () => ({
       editor: null,
       editorCompleto: null,
       titulo:"",
       archivo:'',
      file:null,
      texto:"",
      texto_completo:""
      
    }),
     methods: {
      GuardarRevista(){
        aws_service.savePaper(this.file).then((response) => {
            console.log(response);
            alert("Revista guardada");
              
        }).catch((error) => {
          console.log(error.response.data);
          alert(error.response.data);
        });
        
      },
      AdministrarArchivo(){
        this.archivo = this.$refs.file.files[0];
        console.log(this.archivo);
        
    },
    }
  }
</script>
<style>
.editor
{
  
  border:1px solid #000;
  
}
</style>
