<template>
  <div>
    <Anuncio/>
    <Portada/>
    <Politica/>
    <Colaboracion/>
    <Preguntas/>
    <Revista/>
  </div>
</template>

<script>
  import Anuncio from '../components/CargaAnuncio'
  import Portada from '../components/CargaPortada'
  import Politica from '../components/CargaPolitica'
  import Colaboracion from '../components/CargaColaboracion'
  import Preguntas from '../components/CargaPreguntas'
  import Revista from '../components/CargaRevista'

  export default {
    name: 'Home',

    components: {
      Anuncio,
      Portada,
      Politica,
      Colaboracion,
      Preguntas,
      Revista
    },
  }
</script>
